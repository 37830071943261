import { Grid, IconButton, makeStyles, Paper, TableCell, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import { Build, DoubleArrow, Email, LocalGasStation, Share } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import BidBuy from '../containers/BidBuy';
import CarDetailUserInformation from '../containers/CarDetailUserInformation';
import { useCurrency } from '../hooks/useCurrency';
import { SOURCES_BID_ALLOWED } from '../modules/bid-buy-helpers';
import {
  BidSuccessStatus,
  DetailCarDTO,
  IndividualBidStateInfoDTO,
  SourceType,
  ValidityType,
} from '../modules/generated/api';
import { engineFuelDetails, engineFuelOld, getLabel } from '../modules/labels';
import BidInfoTable from './BidInfoTable';
import CarDetailBoughtCarDeliveryAddress from './CarDetailBoughtCarDeliveryAddress';
import CarDetailCarBasics from './CarDetailCarBasics';
import CarDetailEquipment from './CarDetailEquipment';
import CarDetailTable from './CarDetailTable';
import CarDetailTableHeadCell from './CarDetailTableHeadCell';
import CarDetailTileCo2 from './CarDetailTileCo2';
import CarDetailTileFirstReg from './CarDetailTileFirstReg';
import CarDetailTileHighlights from './CarDetailTileHighlights';
import CarDetailTileTaxes from './CarDetailTileTaxes';
import CarDetailTileUnit from './CarDetailTileUnit';
import CarsTableImageCell from './CarsTableImageCell';
import PrevAuctionsDetailTable from './PrevAuctionsDetailTable';
import RefCarDialog from './RefCarDialog';
import { Menu, MenuAction, useMenu } from './ui';
import { useBidsRequestAutomationStatusByCar } from './cars-table-next/useBids';

enum ShareAction {
  Mail = 'mail',
  Clipboard = 'clipboard',
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  flexWidth: {
    '@media screen and (min-width: 1600px) and (max-width: 1800px)': {
      maxWidth: '80%',
      flexBasis: '80%',
    },
    '@media screen and (min-width: 1801px)': {
      paddingRight: theme.spacing(4),
    },
  },
  fullWidth: {
    '@media screen and (min-width: 960px) and (max-width: 1800px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  imageWrapper: {
    background: theme.palette.grey['200'],
    borderRadius: 0,
    '@media print': {
      boxShadow: 'none',
    },
  },
  highlights: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  printShow: {
    '@media screen': {
      display: 'none',
    },
  },
  printUserDetail: {
    '@media print': {
      paddingTop: theme.spacing(2),
      '& .editBtn': {
        display: 'none',
      },
      '& .MuiAlert-icon': {
        display: 'none',
      },
      '& form': {
        width: '95vw',
      },
    },
  },
  printContainer: {
    '@media print': {
      display: 'flex !important',
    },
  },
  printColImage: {
    '@media print': {
      width: '40%',
      maxWidth: '40%',
      flex: '1 0 auto',
    },
  },
  printColKeyFacts: {
    '@media print': {
      width: '60%',
      maxWidth: '60%',
      flex: '1 0 auto',
    },
  },
  gridWidth: {
    columnGap: theme.spacing(4),
    justifyContent: 'flex-start',
  },
  share: {
    verticalAlign: 'bottom',
    display: 'inline',
  },
}));

type CarDetailContentProps = {
  car: DetailCarDTO;
  refresh: () => Promise<void>;
};

const getMailTo = (subject: string, body: string): string => {
  const template = `mailto:?subject=${subject}&body=${body}`;
  return encodeURI(template);
};

const getCarShareLink = (car: DetailCarDTO): string => {
  const pathname = car.source && car.carId ? `/cars/${car.source}/${car.carId}` : '';
  return [window.location.origin, pathname].join('');
};

const CarDetailContent = ({ car, refresh }: CarDetailContentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isPrintView = useMediaQuery('print');
  const isBigScreen = useMediaQuery('(min-width: 1801px)');
  const otherBought = car.bidStateInfo?.otherCurrentBids?.find(
    (otherBid) => otherBid.status === BidSuccessStatus.Bought,
  );
  const isCarBought = car.bidSuccessStatus === BidSuccessStatus.Bought || !!otherBought;
  const carDetailLink = getCarShareLink(car);
  const { format } = useCurrency();
  const { data: bidAutomationStatus, refetch: refetchBidAutomationStatus } = useBidsRequestAutomationStatusByCar({
    sourceType: car.source!,
    carId: car.carId || '',
  });

  const { isOpen: isShareMenuOpen, open: openShareMenu, close: closeShareMenu } = useMenu();
  const { showError, showSuccess } = useCustomSnackbarGlobal();

  const getFuelLabel = () => {
    if (car.normalized?.fuelDetail) {
      return getLabel(engineFuelDetails, car.normalized.fuelDetail);
    }
    if (car.normalized?.fuel) {
      return getLabel(engineFuelOld, car.normalized.fuel);
    }
    return t('car.power');
  };

  const shareMenuReducer = (action: MenuAction) => {
    switch (action.id) {
      case ShareAction.Mail:
        window.open(
          getMailTo(
            t('carDetailContent.share.subject', { car: car.model }),
            t('carDetailContent.share.body', {
              car: car.model,
              price: car.potential?.base?.amount ? format(car.potential.base, {}) : 'n.A.',
              shareLink: carDetailLink,
            }),
          ),
          'blank',
        );
        break;
      case ShareAction.Clipboard:
        if ('writeText' in navigator.clipboard) {
          navigator.clipboard
            .writeText(getCarShareLink(car))
            .then(() => showSuccess(t('carDetailContent.share.actions.clipboard.success')))
            .catch(() => showError(t('carDetailContent.share.actions.clipboard.error')));
        } else {
          showError(t('carDetailContent.share.actions.clipboard.unsupported'));
        }
        break;
      // no default
    }
  };

  const shareActions: MenuAction[] = [
    {
      id: ShareAction.Mail,
      title: t('carDetailContent.share.actions.mail.label'),
      icon: Email,
    },
    {
      id: ShareAction.Clipboard,
      title: t('carDetailContent.share.actions.clipboard.label'),
      icon: LinkIcon,
    },
  ];

  const showBidBuy =
    car.validity === ValidityType.Current &&
    car.source &&
    SOURCES_BID_ALLOWED.includes(car.source) &&
    car.marketingChannel?.length;

  if (window.location.hash === '#delivery') {
    setTimeout(() => {
      const element = document.getElementById('delivery');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      window.history.replaceState(null, '', window.location.pathname + window.location.search);
    }, 1000);
  }

  return (
    <>
      <Typography variant="h1" className={classes.heading}>
        {car.model}{' '}
        <Menu
          open={isShareMenuOpen}
          onOpen={openShareMenu}
          onClose={closeShareMenu}
          actions={shareActions}
          onAction={shareMenuReducer}
          className={clsx(classes.share, classes.printHide)}
        >
          {(triggerProps) => (
            <IconButton {...triggerProps} color="primary">
              <Share />
            </IconButton>
          )}
        </Menu>
      </Typography>
      <Grid container spacing={4} className={classes.printContainer}>
        <Grid item xs={12} sm={12} md={6} lg={4} className={classes.printColImage}>
          <Paper elevation={3} className={classes.imageWrapper}>
            <CarsTableImageCell
              bought={car.bidSuccessStatus === BidSuccessStatus.Bought}
              otherBought={otherBought}
              isExpired={car.validity === ValidityType.Expired}
              imageLinks={car.imageLinks}
              size="big"
              bidStatus={car.bidStateInfo}
            />
          </Paper>

          <div className={classes.printUserDetail}>
            <CarDetailUserInformation
              car={car}
              carId={car.carId as string}
              source={car.source as SourceType}
              userComment={car.userComment}
              userRatingNamePrefix="printCarDetail"
              refresh={refresh}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8} className={classes.printColKeyFacts}>
          <Grid container className={classes.gridWidth}>
            <Grid item xs={6} lg={3} md={5} xl>
              <CarDetailTileUnit value={car.mileage} unit="km" label={t('car.mileage')} icon={DoubleArrow} />
            </Grid>
            <Grid item xs={6} lg={3} md={5} xl>
              <CarDetailTileFirstReg
                firstRegistration={car.firstRegistration}
                constructionYear={car.constructionYear}
              />
            </Grid>
            {car.tax && (
              <Grid item xs={6} lg={3} md={5} xl>
                <CarDetailTileTaxes tax={car.tax} valuationCountry={car.potential?.country} />
              </Grid>
            )}
            <Grid item xs={6} lg={3} md={5} xl>
              <CarDetailTileCo2
                approxCo2={car.co2ApproximationMethod}
                engineCo2={car.engineCo2}
                engineCo2Nedc={car.engineCo2Nedc}
                engineCo2Wltp={car.engineCo2Wltp}
              />
            </Grid>
            <Grid item xs={6} lg={3} md={5} xl>
              <CarDetailTileUnit
                unit={car.priceDamage?.unit || '€'}
                value={car.priceDamage?.value || 0}
                label={t('car.damage')}
                icon={Build}
              />
            </Grid>

            <Grid item xs={6} lg={3} md={5} xl>
              <CarDetailTileUnit
                unit={car.enginePower?.unit || ''}
                value={car.enginePower?.value || ('' as unknown as number)}
                label={getFuelLabel()}
                icon={LocalGasStation}
              />
            </Grid>

            <Grid item xs={12} className={classes.highlights}>
              <CarDetailTileHighlights car={car} damageType={car.damageType} />
            </Grid>

            <Grid className={classes.printHide} item xs={12}>
              <Grid container>
                <Grid className={classes.flexWidth} item lg xs={12}>
                  <CarDetailCarBasics
                    disableHeader
                    car={car}
                    isBought={isCarBought}
                    otherBought={otherBought}
                    refresh={refresh}
                  />
                </Grid>
                {showBidBuy && isBigScreen && (
                  <Grid lg item>
                    <BidBuy car={car} refresh={refresh} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.printShow}>
          {isPrintView && (
            <CarDetailCarBasics car={car} isBought={isCarBought} otherBought={otherBought} refresh={refresh} />
          )}
        </Grid>

        {showBidBuy && !isBigScreen && (
          <Grid className={clsx(classes.printHide)} item md={6} xs={12}>
            <BidBuy car={car} refresh={refresh} />
          </Grid>
        )}
        <Grid item xs={12} xl={6} md={4}>
          <CarDetailTable title={t('car.damage_plural')}>
            {car.damage?.map(({ name, repairNote }) => (
              <TableRow key={`${name};${repairNote}`}>
                <CarDetailTableHeadCell>{name}</CarDetailTableHeadCell>
                <TableCell>{repairNote}</TableCell>
              </TableRow>
            ))}
          </CarDetailTable>
        </Grid>

        <Grid
          className={clsx(
            classes.printHide,
            car.validity === ValidityType.Current && (car.previousAuctions?.length || 0) > 2 && classes.fullWidth,
          )}
          container
          item
          xl={6}
          md={8}
          xs={12}
          direction="row"
          spacing={2}
        >
          {(!isEmpty(car.bidStateInfo?.allHistoricBids) ||
            !isEmpty(car.bidStateInfo?.otherCurrentBids) ||
            !isEmpty(car.bidStateInfo?.ownCurrentBids)) && (
            <Grid item xs={12}>
              <BidInfoTable
                bids={car.bidStateInfo || ({} as IndividualBidStateInfoDTO)}
                title={t('bids.bidHammers.bidsOverview')}
                source={car.source}
                refresh={refresh}
                dateEnd={car.dateEnd}
                bidId={car.bid?.id}
                carId={car.carId}
                bidAutomationStatus={bidAutomationStatus}
                bidAutomationRefresh={refetchBidAutomationStatus}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PrevAuctionsDetailTable title={`${t('bids.prevAuctions')} (${car.previousAuctions?.length})`} car={car} />
          </Grid>
        </Grid>

        {isCarBought && (
          <Grid item xs={6} id="delivery">
            <CarDetailBoughtCarDeliveryAddress car={car} />
          </Grid>
        )}
        <Grid item xs={12}>
          <CarDetailEquipment equipmentCodeText={car.equipmentCodeText} />
        </Grid>
      </Grid>
      <RefCarDialog />
    </>
  );
};
export default memo(CarDetailContent);
